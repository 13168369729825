import { useEffect } from 'react';
import { useTrackTitanStore } from 'src/zustand/store';
import { UserType } from 'src/types';
import { getUser } from './useAuthEvents';
import { ICredentials } from '@aws-amplify/core';
import axios from 'axios';
import { BenefitsResponse } from 'src/types/new';
import { useTrackTitanHydratedStore } from 'src/zustand/hydrated';
import { getUserDetails } from 'src/queries/user/getUserDetails';
import { getUserBenefitData } from './getUserBenefitData';

const stagingUserIds = [
    '7168cc5f-4ab1-4aff-9037-201c53accb15', // community@titanacademy.io
    '36878ee0-3f63-4a50-9910-817247d00538', // trial@titanacademy.io
    'f809cfe1-241e-4fc0-a182-71fc767161e9', // plus@titanacademy.io
    '1917e03b-1a59-41a2-b340-1fb65b5f93f1' // premium@titanacademy.io
];
const prodUserIds = [
    '4614e67f-7dac-41e0-acad-cf36422dea4e', // community@titanacademy.io
    '180f3e0f-79a3-45a5-8cad-77c4e20943bc', // trial@titanacademy.io
    '7661fd5a-c11e-43a1-a0ef-3460e8d19176', // plus@titanacademy.io
    '19f0f793-3a4e-4155-b6ca-b8c054a2934f' // premium@titanacademy.io
];

export const impersonateUserAdminUserIds =
    process.env.NEXT_PUBLIC_SANITY_DATASET === 'staging' ? stagingUserIds : prodUserIds;

const impersonateUser = async (
    user: UserType | undefined,
    authUser: UserType | undefined,
    impersonationUserId: string,
    setImpersonationUserId: (impersonationUserId: string) => void,
    setUser: (user: UserType | undefined, impersonatedUser?: boolean) => void,
    setUserCredentials: (userCredentials: ICredentials | undefined) => void,
    setIsCheckingAuth: (isCheckingAuth: boolean) => void
) => {
    try {
        if (impersonationUserId !== '') {
            if (!impersonateUserAdminUserIds.includes(authUser?.uuid ?? '')) {
                // Not valid admin user ID
                setImpersonationUserId('');
                return;
            }

            let user: UserType = {
                email: 'impersonatedUser@email.com',
                uuid: impersonationUserId,
                cognitoUsername: 'Impersonated User',
                userType: 'sim_racer'
            };

            // database data
            const usernameData = await getUserDetails(impersonationUserId);

            const partialUser = await getUserBenefitData(impersonationUserId);

            user = { ...user, ...partialUser };

            setUser({ ...user, displayName: usernameData?.username ?? '' }, true);
        } else if (user && user.uuid && !user.auth) {
            getUser(setUser, setUserCredentials, setIsCheckingAuth);
        }
    } catch {
        // Benefits or username API call failed, probably invalid uuid
        setImpersonationUserId('');
    }
};

export const useImpersonateUser = () => {
    const { user, authUser, impersonationUserId } = useTrackTitanHydratedStore((state) => state.auth);

    const { setImpersonationUserId, setUser, setUserCredentials, setIsCheckingAuth } = useTrackTitanStore(
        (state) => state.auth
    );

    useEffect(() => {
        impersonateUser(
            user,
            authUser,
            impersonationUserId,
            setImpersonationUserId,
            setUser,
            setUserCredentials,
            setIsCheckingAuth
        );
    }, [impersonationUserId]);
};
